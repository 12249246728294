// 为了保持在waterFall中的顺序，点推与点刷需保持顺序
class DelayRefresh {
  constructor() {}
  awaitSlotRequest = null // 1. 等待点推 请求结束 -> 请求都结束后，开始执行点刷删除
  awaitRefreshRequest = null // 1. 等待点刷 请求结束 -> 请求都结束后，开始执行点刷删除
  awaitRefreshDelete = null // 2. 等待点刷 删除商品 -> 删除后，开始点推新增商品
  awaitSlotAdd = null // 3. 等待点推 新增商品 -> 点推新增后，开始点刷新增商品

  awaitSlotRequestResolve = null
  awaitRefreshRequestResolve = null
  awaitRefreshDeleteResolve = null
  awaitSlotAddResolve = null
  // 初始化 等待点推 请求结束
  initSlotRequestPromise() {
    this.awaitSlotRequest = new Promise(resolve => {
      this.awaitSlotRequestResolve = resolve
    })
  }
  // 初始化 等待点刷 请求结束
  initRefreshRequestPromise() {
    this.awaitRefreshRequest = new Promise(resolve => {
      this.awaitRefreshRequestResolve = resolve
    })
  }
  // 初始化 等待点刷 删除商品
  initRefreshDeletePromise() {
    this.awaitRefreshDelete = new Promise(resolve => {
      this.awaitRefreshDeleteResolve = resolve
    })
  }
  // 初始化 等待点推 新增商品
  initSlotAddPromise() {
    this.awaitSlotAdd = new Promise(resolve => {
      this.awaitSlotAddResolve = resolve
    })
  }

  // 点推 请求结束
  resolveSlotRequest(event) {
    this.awaitSlotRequestResolve && this.awaitSlotRequestResolve(event)
  }

  // 监听 点推 请求结束
  async listenSlotRequestPromise(once = true) {
    if (this.awaitSlotRequest) {
      const res = await this.awaitSlotRequest
      if (once) {
        this.awaitSlotRequest = null
      }
      return res
    }
  }

  // 点刷 请求结束
  resolveRefreshRequest(event) {
    this.awaitRefreshRequestResolve && this.awaitRefreshRequestResolve(event)
  }

  // 监听 点刷 请求结束
  async listenRefreshRequestPromise(once = true) {
    if (this.awaitRefreshRequest) {
      const res = await this.awaitRefreshRequest
      if (once) {
        this.awaitRefreshRequest = null
      }
      return res
    }
  }
  // 点刷 删除商品
  resolveRefreshDelete(event) {
    this.awaitRefreshDeleteResolve && this.awaitRefreshDeleteResolve(event)
  }

  // 监听 点刷 删除商品
  async listenRefreshDeletePromise(once = true) {
    if (this.awaitRefreshDelete) {
      const res = await this.awaitRefreshDelete
      if (once) {
        this.awaitRefreshDelete = null
      }
      return res
    }
  }
  // 点推 新增商品
  resolveSlotAdd(event) {
    this.awaitSlotAddResolve && this.awaitSlotAddResolve(event)
  }

  // 监听 点推 新增商品
  async listenSlotAddPromise(once = true) {
    if (this.awaitSlotAdd) {
      const res = await this.awaitSlotAdd
      if (once) {
        this.awaitSlotAdd = null
      }
      return res
    }
  }
}

export default DelayRefresh
