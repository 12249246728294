export const commonComponents = {
  OneBigImgSlider: () => import('./OneBigImgSlider'), // 一大图轮播样式
  Operation: () => import('./Operation'), // 运营位样式
  // OneBigImgAndThreeSmallImg: () => import('./OneBigImgAndThreeSmallImg'), // 一大图三小图样式, 一大图三小图样式暂时没有使用，代码先保留
}
const componentMap = {
  12: 'OneBigImgSlider',
  13: 'OneBigImgSlider',
  62: 'TrendContent',
  19: 'Operation',
  63: 'FashionStore',
  84: 'BehaviorProductSet',
}

//新增内容体需要在这里补充
export const CONTENT_LIST = [12, 13, 62, 63]

export const getComponentsName = (ext)=> {
  if(ext.carrierType == '19' || ext.carrierType == '84'){
    return componentMap[ext.carrierType]
  }
  // 12:新品 13:折扣 62: 趋势
  if([12, 13, 62, 63].includes(Number(ext.carrierSubType))){
    return componentMap[ext.carrierSubType]
  }
}
