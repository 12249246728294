<template>
  <div
    class="bottom-suspension"
    :class="{
      'home-popover__hidden': hiddenBottomIcon
    }"
    :style="{
      'transform': !appContent?.BottomSuspensionIconClosed ? `translate3D(${isCssRight ? '' : '-'}50%, 0px, 0)` : `translate3D(${isCssRight ? '' : '-'}50%, 1.07rem, 0)`,
      'transition': !appContent?.BottomSuspensionIconClosed ? 'transform 0.25s cubic-bezier(0.3, 0, 0.1, 1)' : 'transform 0.25s cubic-bezier(0.3, 0, 1, 1)',
      'bottom': isSecondaryHome ? 0 : '51px',
    }"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
    @click="goToProductPage"
  >
    <!-- 展开背景图 -->

    <TransformImg
      v-show="fullImage?.src && !isShowTitle"
      class="bottom-suspension__full-image"
      :img-src="fullImage?.src"
      :is-first-page="isFirstPage"
      img-design-width="264"
      :style="{
        'transform': appContent?.BottomSuspensionIconClosed ? 'translate3D(0, 100%, 0)' : isExpended ? 'translate3D(0, 0px, 0)' : 'translate3D(0, 100%, 0)',
        'transition': isExpended ? 'transform 0.25s cubic-bezier(0.3, 0, 0.1, 1)' : 'transform 0.25s cubic-bezier(0.3, 0, 1, 1)',
        ...getImgStyle('fullImage')
      }"
    />
    <!-- 收起背景图 -->
    <TransformImg
      v-show="collapseImage?.src && !isShowTitle"
      class="bottom-suspension__collapse-image"
      :img-src="collapseImage?.src"
      :is-first-page="false"
      img-design-width="264"
      :style="{
        'transform': appContent?.BottomSuspensionIconClosed ? 'none' : (!isExpended) ? 'translate3D(0, 0px, 0)' : 'translate3D(0, 100%, 0)',
        'transition': appContent?.BottomSuspensionIconClosed ? 'none' : !isExpended ? 'transform 0.25s cubic-bezier(0.3, 0, 0.1, 1)' : 'transform 0.25s cubic-bezier(0.3, 0, 1, 1)',
        ...getImgStyle('collapseImage')
      }"
    />
    <!-- 标题图 -->
    <TransformImg
      v-show="titleImage?.src && (!appContent?.BottomSuspensionIconClosed || !sliderCollapse)"
      class="bottom-suspension__title-image"
      :src="titleImage?.src"
      :is-first-page="isFirstPage"
      img-design-width="264"
      :style="{
        'transform': (isExpended || !sliderCollapse) ? 'translate3D(0, 0px, 0)' : 'translate3D(0, 100%, 0)',
        'transition': (isExpended || !sliderCollapse) ? 'transform 0.25s cubic-bezier(0.3, 0, 0.1, 1)' : 'transform 0.25s cubic-bezier(0.3, 0, 1, 1)',
        ...getImgStyle('titleImage')
      }"
    />
   
    <!-- 商品图 -->
    <swiper-container
      v-if="productList.length && !appContent?.BottomSuspensionIconClosed && !isShowTitle"
      ref="iconSwiperInstance"
      :style="{
        'border': `1.5px solid ${borderColor}`,
        'boxSizing': 'content-box',
        'bottom': isExpended ? (productShape === 'circular' ? '0.3rem' : '1px') : 0,
        'borderRadius': productShape === 'circular' ? '50%' : '3px',
        'transform': isExpended ? 'translate3D(0, 0px, 0)' : 'translate3D(0, 100%, 0)',
        'transition': isExpended ? 'transform 0.25s cubic-bezier(0.3, 0, 0.1, 1)' : 'transform 0.25s cubic-bezier(0.3, 0, 1, 1)',
      }"
      :class="{ 'ccc-swiper__container-circular': productShape === 'circular' }"
      class="ccc-swiper__container"
      init="false"
      destroy-on-disconnected="false"
      @click.stop="goToProductPage"
    >
      <swiper-slide
        v-for="(item, index) in productList"
        :key="`item-${index}`"
        class="ccc-swiper__slide"
      >
        <TransformImg
          v-expose="getProductDataAnalysis({ id: '2-22-3', index, item })"
          :class="{
            'bottom-suspension__goods-image': true, 
            'bottom-suspension__goods-image-circular': productShape === 'circular',
            'fsp__bottom-suspension-icon': true
          }"
          fit="cover"
          :img-src="item.goods_img"
          :is-first-page="isFirstPage && index < 1"
          img-design-width="128"
        />
      </swiper-slide>
    </swiper-container>
    <!-- 仅展示标题（isShowTitle）状态开时，展开样式下也需要展示关闭按钮 -->
    <i
      v-show="(collapseImage?.src || titleImage?.src) &&
        showCloseImage &&
        (!isShowTitle && !isExpended && !sliderCollapse) ||
        (isShowTitle && (isExpended || !sliderCollapse))"
      v-enterkey
      class="bottom-suspension__close iconfont icon-close"
      tabindex="0"
      role="button"
      :style="getIconStyle"
      :aria-label="SHEIN_KEY_PWA_15567"
      @click.stop="close"
    >
    </i>
  </div>
</template>

<script>
import TransformImg from 'public/src/pages/components/transformImg/index.vue'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { getCccProductList } from 'public/src/services/api/ccc.js'
const { language: { SHEIN_KEY_PWA_15567 } = {} } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { preFetchOrGetPageUrl } from 'public/src/services/lazyComponent/lazy-hydrate/pre-fetch/utils.js'
import expose from 'public/src/services/expose/index.js'

import { Autoplay } from 'swiper/modules'
import { register } from 'swiper/element'
typeof window !== 'undefined' && register()
import { mapState } from 'vuex'
  
import { getListCommonParams }  from 'public/src/pages/components/ccc/common/utils.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import { getQueryString, debounce, throttle } from '@shein/common-function'

export default {
  components: {
    TransformImg,
  },
  mixins: [ mixin ],
  props: {
    propData: {
      type: Object,
      default: () => {}
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    appContent: {
      type: Object,
      default() {
        return {}
      }
    },
    // 二级首页
    isSecondaryHome: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const { props = {}, productList = [] } = this.propData || {}
    const { metaData = {} } = props || {}
    const {
      titleImage, // 标题图片
      fullImage,  // 展开背景图
      collapseImage, // 收起背景图
      cateType, // 分类类型
      cateId, // 分类id
      hrefTarget, 
      hrefType, 
      adp, 
      mallCodes, 
      borderColor, // 边框颜色
      productShape, // 商品形状图，取值为circular--圆形，rectangle--方形
      sliderCollapse, // 滑动时标题图收起
      isShowTitle, // 仅展示标题图(不展示商品/展开/折叠背景图) [wiki: pageId=1493703990]
    } = metaData || {}
    return {
      isFirstPage: false,
      titleImage,
      fullImage,
      collapseImage,
      cateType,
      cateId,
      hrefTarget,
      hrefType,
      adp,
      mallCodes,
      borderColor,
      productShape,
      sliderCollapse,
      SHEIN_KEY_PWA_15567,
      productList,
      isCssRight: gbCommonInfo.GB_cssRight,
      startTime: 0,
      endTime: 0,
      timer: null,
      isExpended: true, // 展开收起状态
      onceExposeCollapse: true, // 用于标记收起状态下的悬浮icon是否曝光过
      showCloseImage: true, // 收起背景图或标题图未加载出来时，不显示关闭按钮
      iconSwiperInstance: null, 
      observerInstance: null,
      isShowTitle,
      beforeScrollTop: 0,
      separation: false,
      separationTimeout: null,
      stopScrollTimeout: null,
    }
  },
  computed: {
    ...mapState(['hiddenBottomIcon']),
    // 获取删除按钮的相对位置
    getIconStyle() {
      let titletLeft = 0
      let collapseLeft = 0
      let maxHeight =  40
      if (!this.sliderCollapse || this.isShowTitle)  {
        let radio = this.titleImage.height / (maxHeight * 3)
        if (radio > 1) {
          titletLeft = this.titleImage.width / (radio * 2 * 3)
        } else {
          titletLeft = this.titleImage.width / (2 * 3)
        }
      }
      if (this.collapseImage?.src) {
        let radio = this.collapseImage.height / (maxHeight * 3)
        if (radio > 1) {
          collapseLeft = this.collapseImage.width / (radio * 2 * 3)
        } else {
          collapseLeft = this.collapseImage.width / (2 * 3)
        }
      }
      const iconLeft = `${titletLeft >  collapseLeft ? (titletLeft / 37.5) + 20 / 75 : (collapseLeft / 37.5) + 20 / 75}rem`
      return this.isCssRight ? {
        right: iconLeft
      } : {
        left: iconLeft
      }
    },
    cateLinks() {
      return this.appContent?.cateLinks
    },
    sceneData() {
      return {
        pageType: this.appContent?.pageType || '',
        sceneName: this.appContent?.channelName || '',
        pageFrom: 'homePage',
      }
    },
  },
  watch: {
    propData: {
      handler() {
        const { props = {}, productList = [] } = this.propData || {}
        const { metaData = {} } = props || {}
        this.onceExposeCollapse = true
        const {
          titleImage, // 标题图片
          fullImage,  // 展开背景图
          collapseImage, // 收起背景图
          cateType, // 分类类型
          cateId, // 分类id
          hrefTarget,
          hrefType,
          adp, 
          mallCodes, 
          borderColor, // 边框颜色
          productShape, // 商品形状图，取值为circular--圆形，rectangle--方形
          sliderCollapse, // 滑动时标题图收起
        } = metaData || {}
        this.titleImage = titleImage
        this.fullImage = fullImage
        this.collapseImage = collapseImage
        this.cateType = cateType
        this.cateId = cateId
        this.hrefTarget = hrefTarget
        this.hrefType = hrefType
        this.adp = adp
        this.mallCodes = mallCodes
        this.borderColor = borderColor
        this.productShape = productShape
        this.sliderCollapse = sliderCollapse
        this.productList = productList
      },
      immediate: true
    },
    isExpended: {
      handler(val) {
        if (!val && this.onceExposeCollapse) {
          if(!this.sliderCollapse && this.titleImage?.src) {
            const image = new Image()
            image.src = this.titleImage.src
            image.onload = () => {
              this.showCloseImage = true
            }
          }
          if(this.collapseImage?.src) {
            const image = new Image()
            image.src = this.collapseImage.src
            image.onload = () => {
              this.showCloseImage = true
            }
          }
          this.$nextTick(() => {
            daEventCenter.triggerNotice({
              daId: '2-22-1',
              bindData: [
                {
                  sceneData: this.sceneData,
                  propData: this.propData,
                  compIndex: '',
                  item: {
                    hrefType: this.hrefType,
                    hrefTarget: this.hrefTarget,
                    item_loc: '0',
                    oprt_loc: '1',
                  },  
                  index: 0,  
                  isJumpToList: true,
                  act_nm: 'BottomFloating_folded'
                }
              ]
            })
            this.onceExposeCollapse = false
          })
        }
      },
      immediate: true
    },
    isVisible: {
      handler(val) {
        if (val) {
          if(typeof window === 'undefined') return
          this.isExpended = true
          // 移除事件监听器
          window.removeEventListener('scroll', this.scrollFun)
          setTimeout(() => {
            this.initScroll()
          }, 0)
        }
      },
      immediate: true
    }
  },
  created() {
    this.productList?.length && (this.isFirstPage = true)
  },
  async mounted () {
    // 标题图不存在告警
    if(!this.titleImage?.src || getQueryString({ key: 'debugTitleImageEmpty' })){
      SILogger.logInfo({
        tag: 'home_bottom_icon_empty_title_image',
        message: 'HomeBottomIcon is empty title image',
        params: {
          scene: 'home_bottom_icon',
          msgData: '标题图为空'
        }
      })
    }
    if (!this.productList.length) {
      if(!this.cateId) return
      const result = await this.getProductList({ cateType: this.cateType, cateId: this.cateId, limitNum: 10, adp: this.adp, mallCodes: this.mallCodes })
      this.productList = result?.list?.length > 1 ? result.list : []
      this.propData.productList = this.productList
    } 
    this.initScroll()
    this.preFetchCampaigns()
    this.initSwiper()
    // 存在轮播图，上报日志
    if(this.productList.length) {
      this.productList.map(el => {
        if(!el.goods_img) {
          this.hideCompSls({
            logLevel: 3,
            tag: 'web_client_home_error_total',
            message: 'HomeOtherDataError',
            reason: 'imageError',
          })
        }
      })
      if(!this.appContent?.BottomSuspensionIconClosed) {
        Monitor.metricFloatComp({
          tags: {
            track_type: '3',
            layer_type: 'ModalsBottomSuspension',
          },
        })
      }
    }
  },
  deactivated () {
    // 移除事件监听器
    window.removeEventListener('scroll', this.scrollFun)
    
    this.timer && clearTimeout(this.timer)
    
    if(this.iconSwiperInstance){
      this.iconSwiperInstance.animating = false
    }
  },
  activated () {
    this.isExpended = true
    setTimeout(() => {
      this.initScroll()
      this.iconSwiperInstance?.autoplay?.start?.()
    }, 0)
  },
  beforeDestroy() {
    this.iconSwiperInstance?.destroy()
    delete this.iconSwiperInstance
    
    this.observerInstance?.destroy?.()
    this.observerInstance = null
  },
  methods: {
    // 初始化 swiper
    initSwiper() {
      // swiper element
      const swiperEl = this.$refs.iconSwiperInstance
      if (!swiperEl) return
      // swiper parameters
      const swiperParams = {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        modules: [Autoplay],
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        allowTouchMove: false,
        loop: true,
        spaceBetween: 0,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swipe
      }
      
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      
      this.handleSwiperInit(swiperEl?.swiper)
      this.initSwiperObserver(swiperEl, swiperEl?.swiper)
    },
    initSwiperObserver(swiperEl, swiperInstance) {
      this.observerInstance = new expose({ observeHide: true })
      this.observerInstance.observe({
        elements: swiperEl,
        once: false,
      }, ({ exposeDoms }) => {
        if (!swiperInstance) return

        if (exposeDoms.length) {
          // 进入视口
          swiperInstance.autoplay?.start?.()
        } else {
          // 离开视口
          swiperInstance.autoplay?.stop?.()
        }
      })
    },
    preFetchCampaignsPage() {
      const params = {
        item: {
          hrefType: this.hrefType,
          hrefTarget: this.hrefTarget,
          item_loc: '',
        },
        cateLinks: this.cateLinks,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      preFetchOrGetPageUrl({ url, options: { scene: 'special' } })
    },
    // 预取专题页面
    preFetchCampaigns() {
      if (this.hrefType !== 'activityNew') {
        return
      } 
      this.preFetchCampaignsPage()
    },
    handleSwiperInit(swiper) {
      this.iconSwiperInstance = swiper
    },
    // 节流
    scrollFun: throttle({ 
      func: function() {
        let afterScrollTop
        let scrollDelta
        if (!this.separation) {
          clearTimeout(this.separationTimeout)
          this.separationTimeout = setTimeout(() => {
            afterScrollTop = window?.scrollY || 0
            scrollDelta = afterScrollTop - this.beforeScrollTop
            if (Math.abs(scrollDelta) > 100) {
              this.isExpended = false
            }
            this.beforeScrollTop = afterScrollTop
            this.separation = false
          }, 300)
          this.stopScrollTimeout && clearTimeout(this.stopScrollTimeout)
          this.stopScrollTimeout = setTimeout(() => {
            if (this.appContent.BottomSuspensionIconClosed) return
            this.isExpended = true
          }, 2000)
        }
        this.separation = true
      },
      wait: 300,
    }),
    initScroll () {
      this.beforeScrollTop = window?.scrollY || 0
      this.separation = false
      window.addEventListener('scroll', this.scrollFun)
    },
    // 跳商品列表
    goToProductPage () {
      let realIndex = this.iconSwiperInstance?.realIndex || 0
      let item = this.productList[realIndex]
      // 由于阻止了事件冒泡，所以这里需要手动触发埋点事件
      daEventCenter.triggerNotice({
        daId: item?.goods_id ? '2-22-4' : '2-22-2',
        bindData: item?.goods_id ? {
          sceneData: this.sceneData,
          propData: this.propData,
          compIndex: '',
          item: {
            hrefType: this.hrefType,
            hrefTarget: this.hrefTarget,
            item_loc: this.isShowTitle ? realIndex : realIndex + 1,
            oprt_loc: '1',
          }, 
          product: item,
          index: 0,  
          isJumpToList: true,
          act_nm: this.isExpended ? 'BottomFloating_normal' : 'BottomFloating_folded',
        } : this.getAnalysis({}).data
      })

      const params = {
        item: {
          hrefType: this.hrefType,
          hrefTarget: this.hrefTarget,
          item_loc: '',
        },
        cateLinks: this.cateLinks,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      if (this.hrefType !== 'activityNew') {
        const goodsIdList = this.productList.map((i) => i.goods_id)
        const sortGoods = [item?.goods_id, ...goodsIdList.filter(i => i !== item?.goods_id)]
        const adpStr = `adp=${item?.goods_id ? sortGoods.join(',') : goodsIdList.join(',')}`
        url = url.indexOf('?') > -1 ? `${url}&${adpStr}` : `${url}?${adpStr}`
      }
      this.jumpLink({ url, hrefType: this.hrefType, scene: 'special' })
    },
    handleTouchStart () {
      this.startTime = +new Date()
      this.timer && clearTimeout(this.timer)
    },
    // 从CCC配置的图都为三倍图，这里需要手动除以3进行适配
    getImgStyle(type) {
      return {
        height: `${this[type]?.height / (37.5 * 3)}rem`,
      }
    },
    handleTouchEnd () {
      this.endTime = +new Date()
      if (this.endTime - this.startTime > 500) {
        this.isExpended = false
        // 处理点击事件
        this.timer = setTimeout(() => {
          this.isExpended = true
        }, 2000)
        
      } 
    },
    async getProductList({ cateType, cateId, limitNum, adp, mallCodes }) {
      const params = {
        ...getListCommonParams({ sceneData: this.sceneData, propData: this.propData }),
        scene: this.sceneData.pageFrom,
        cateType: cateType,
        cateId: cateId,
        mallCodes: mallCodes,
        limit: limitNum,
        adp
      }
      const result = await getCccProductList(params)
      return result?.data
    },
    getAnalysis({ id }) {
      return this.getAnalysisData(id, {
        item: {
          hrefType: this.hrefType,
          hrefTarget: this.hrefTarget,
          item_loc: '0',
          oprt_loc: '1',
        },
        isJumpToList: true,
        act_nm: this.isExpended ? 'BottomFloating_normal' : 'BottomFloating_folded',
      }, true)
    },
    getProductDataAnalysis({ id, index, item }) {
      return this.getProductAnalysis(id, {
        index: index,
        compIndex: '',
        item,
        cate: {
          hrefType: this.hrefType,
          hrefTarget: this.hrefTarget,
          item_loc: index + 1,
          oprt_loc: '1',
        },
        act_nm: this.isExpended ? 'BottomFloating_normal' : 'BottomFloating_folded',
        isJumpToList: true,
      })
    },
    close() {
      daEventCenter.triggerNotice({
        daId: '2-22-2',
        bindData: {
          sceneData: this.sceneData,
          propData: this.propData,
          compIndex: 0,
          item: {
            hrefType: this.hrefType,
            hrefTarget: this.hrefTarget,
            item_loc: this.isShowTitle ? '0' : '1',
            oprt_loc: '1',
          },  
          index: 0,  
          isJumpToList: true,
          act_nm: 'BottomFloating_close'
        }
      })
      this.appContent.BottomSuspensionIconClosed = true
      this.$forceUpdate()
      Monitor.metricFloatComp({
        tags: {
          track_type: '4',
          layer_type: 'ModalsBottomSuspension',
        },
      })
    },
  }
}
</script>
<style lang="less">
.home-popover__hidden {
  visibility: hidden;
  pointer-events: none;
}
.bottom-suspension {
  position: fixed;
  left: 50vw;
  display: flex;
  justify-content: center;
  transform: translate(-50%);
  z-index: @zindex-fixedcheckout;
  //margin-bottom: constant(safe-area-inset-bottom);
  //margin-bottom: env(safe-area-inset-bottom);
  &__title-image {
    position: absolute;
    bottom: 0;
    max-height: 80/75rem;
    object-fit: contain;
    z-index: @zindex-out;
  }
  &__full-image {
    position: absolute;
    bottom: 0;
    max-height: 180/75rem;
    object-fit: contain;
  }
  &__collapse-image {
    position: absolute;
    bottom: 0;
    max-height: 80/75rem;
    object-fit: contain;
  }
  .ccc-swiper__container {
    position: absolute;
    bottom: 1px;
    height: 168/75rem;
    width: 126/75rem;
    z-index: @zindex-hack;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    background: #ffffff;
  }
  .ccc-swiper__slide {
    --slides-per-view: 1;
    width: calc(100% / var(--slides-per-view));
    flex-shrink: 0;
  }
  .ccc-swiper__container-circular {
    width: 140/75rem;
    height: 140/75rem;
  }
  &__goods-image {
    position: absolute;
    bottom: 0;
    width: 126/75rem;
    height: 168/75rem;
    z-index: @zindex-hack;
    object-fit: cover;// 保证宽高比例不变
    left: 50%;
    transform: translate(-50%, 0px);
  }
  &__goods-image-circular { 
    width: 140/75rem;
    height: 140/75rem;
  }
  &__close {
    box-sizing: content-box;
    position: relative;
    top: -20/75rem;
    padding: .133rem;
    transform: scale(0.5);
    font-size: 12px;
    width: .8rem;
    height: .8rem;
    line-height: .8rem;
    border-radius: 50%;
    background: rgba(102, 102, 102, 0.6);
    background-clip: content-box;
    color: #fff;
    font-weight: bold;
    text-align: center;
    z-index: @zindex-out;
  }
}
</style>
