var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-suspension",class:{
    'home-popover__hidden': _vm.hiddenBottomIcon
  },style:({
    'transform': !_vm.appContent?.BottomSuspensionIconClosed ? `translate3D(${_vm.isCssRight ? '' : '-'}50%, 0px, 0)` : `translate3D(${_vm.isCssRight ? '' : '-'}50%, 1.07rem, 0)`,
    'transition': !_vm.appContent?.BottomSuspensionIconClosed ? 'transform 0.25s cubic-bezier(0.3, 0, 0.1, 1)' : 'transform 0.25s cubic-bezier(0.3, 0, 1, 1)',
    'bottom': _vm.isSecondaryHome ? 0 : '51px',
  }),on:{"touchstart":_vm.handleTouchStart,"touchend":_vm.handleTouchEnd,"click":_vm.goToProductPage}},[_c('TransformImg',{directives:[{name:"show",rawName:"v-show",value:(_vm.fullImage?.src && !_vm.isShowTitle),expression:"fullImage?.src && !isShowTitle"}],staticClass:"bottom-suspension__full-image",style:({
      'transform': _vm.appContent?.BottomSuspensionIconClosed ? 'translate3D(0, 100%, 0)' : _vm.isExpended ? 'translate3D(0, 0px, 0)' : 'translate3D(0, 100%, 0)',
      'transition': _vm.isExpended ? 'transform 0.25s cubic-bezier(0.3, 0, 0.1, 1)' : 'transform 0.25s cubic-bezier(0.3, 0, 1, 1)',
      ..._vm.getImgStyle('fullImage')
    }),attrs:{"img-src":_vm.fullImage?.src,"is-first-page":_vm.isFirstPage,"img-design-width":"264"}}),_vm._v(" "),_c('TransformImg',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapseImage?.src && !_vm.isShowTitle),expression:"collapseImage?.src && !isShowTitle"}],staticClass:"bottom-suspension__collapse-image",style:({
      'transform': _vm.appContent?.BottomSuspensionIconClosed ? 'none' : (!_vm.isExpended) ? 'translate3D(0, 0px, 0)' : 'translate3D(0, 100%, 0)',
      'transition': _vm.appContent?.BottomSuspensionIconClosed ? 'none' : !_vm.isExpended ? 'transform 0.25s cubic-bezier(0.3, 0, 0.1, 1)' : 'transform 0.25s cubic-bezier(0.3, 0, 1, 1)',
      ..._vm.getImgStyle('collapseImage')
    }),attrs:{"img-src":_vm.collapseImage?.src,"is-first-page":false,"img-design-width":"264"}}),_vm._v(" "),_c('TransformImg',{directives:[{name:"show",rawName:"v-show",value:(_vm.titleImage?.src && (!_vm.appContent?.BottomSuspensionIconClosed || !_vm.sliderCollapse)),expression:"titleImage?.src && (!appContent?.BottomSuspensionIconClosed || !sliderCollapse)"}],staticClass:"bottom-suspension__title-image",style:({
      'transform': (_vm.isExpended || !_vm.sliderCollapse) ? 'translate3D(0, 0px, 0)' : 'translate3D(0, 100%, 0)',
      'transition': (_vm.isExpended || !_vm.sliderCollapse) ? 'transform 0.25s cubic-bezier(0.3, 0, 0.1, 1)' : 'transform 0.25s cubic-bezier(0.3, 0, 1, 1)',
      ..._vm.getImgStyle('titleImage')
    }),attrs:{"src":_vm.titleImage?.src,"is-first-page":_vm.isFirstPage,"img-design-width":"264"}}),_vm._v(" "),(_vm.productList.length && !_vm.appContent?.BottomSuspensionIconClosed && !_vm.isShowTitle)?_c('swiper-container',{ref:"iconSwiperInstance",staticClass:"ccc-swiper__container",class:{ 'ccc-swiper__container-circular': _vm.productShape === 'circular' },style:({
      'border': `1.5px solid ${_vm.borderColor}`,
      'boxSizing': 'content-box',
      'bottom': _vm.isExpended ? (_vm.productShape === 'circular' ? '0.3rem' : '1px') : 0,
      'borderRadius': _vm.productShape === 'circular' ? '50%' : '3px',
      'transform': _vm.isExpended ? 'translate3D(0, 0px, 0)' : 'translate3D(0, 100%, 0)',
      'transition': _vm.isExpended ? 'transform 0.25s cubic-bezier(0.3, 0, 0.1, 1)' : 'transform 0.25s cubic-bezier(0.3, 0, 1, 1)',
    }),attrs:{"init":"false","destroy-on-disconnected":"false"},on:{"click":function($event){$event.stopPropagation();return _vm.goToProductPage.apply(null, arguments)}}},_vm._l((_vm.productList),function(item,index){return _c('swiper-slide',{key:`item-${index}`,staticClass:"ccc-swiper__slide"},[_c('TransformImg',{directives:[{name:"expose",rawName:"v-expose",value:(_vm.getProductDataAnalysis({ id: '2-22-3', index, item })),expression:"getProductDataAnalysis({ id: '2-22-3', index, item })"}],class:{
          'bottom-suspension__goods-image': true, 
          'bottom-suspension__goods-image-circular': _vm.productShape === 'circular',
          'fsp__bottom-suspension-icon': true
        },attrs:{"fit":"cover","img-src":item.goods_img,"is-first-page":_vm.isFirstPage && index < 1,"img-design-width":"128"}})],1)}),1):_vm._e(),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:((_vm.collapseImage?.src || _vm.titleImage?.src) &&
      _vm.showCloseImage &&
      (!_vm.isShowTitle && !_vm.isExpended && !_vm.sliderCollapse) ||
      (_vm.isShowTitle && (_vm.isExpended || !_vm.sliderCollapse))),expression:"(collapseImage?.src || titleImage?.src) &&\n      showCloseImage &&\n      (!isShowTitle && !isExpended && !sliderCollapse) ||\n      (isShowTitle && (isExpended || !sliderCollapse))"},{name:"enterkey",rawName:"v-enterkey"}],staticClass:"bottom-suspension__close iconfont icon-close",style:(_vm.getIconStyle),attrs:{"tabindex":"0","role":"button","aria-label":_vm.SHEIN_KEY_PWA_15567},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }